/* Global styles
================================================== */

html {
  overflow-x: hidden !important;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  border: 0;
  margin: 0;
  padding: 0;
}

.body-inner {
  position: relative;
  overflow: hidden;
}

.preload {
  position: fixed;
  background: #fff url(../images/preload.gif) center center no-repeat;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

a:link,
a:visited {
  text-decoration: none;
}

a {
  color: inherit;
  transition: .2s ease;
}

a:hover {
  text-decoration: none;
  color: #000;
}

a.read-more {
  color: #ff6500;
  font-weight: 700;
  text-transform: uppercase;
}

a.read-more:hover {
  color: #222;
}

section,
.section-padding {
  padding: 70px 0;
  position: relative;
}

.no-padding {
  padding: 0;
}

.gap-60 {
  clear: both;
  height: 60px;
}

.gap-40 {
  clear: both;
  height: 40px;
}

.gap-30 {
  clear: both;
  height: 30px;
}

.gap-20 {
  clear: both;
  height: 20px;
}

.mrb-30 {
  margin-bottom: 30px;
}

.mrb-80 {
  margin-bottom: -80px;
}

.mrt-0 {
  margin-top: 0 !important;
  margin-bottom: 20px !important;
}

.pab {
  padding-bottom: 0;
}

a:focus {
  outline: 0;
}

.ts-padding {
  padding: 60px;
}

.solid-bg {
  background: #f9f9f9;
}

.dark-bg{
  background: #252525;
  color: #fff;
}

.dark-bg h2, 
.dark-bg h3{
  color: #fff;
}

.solid-row {
  background: #f0f0f0;
}

.bg-overlay {
  position: relative;
}

.bg-overlay .container {
  position: relative;
  z-index: 1;
}

.bg-overlay:after {
  background-color: rgba(0, 46, 91, 0.8);
  z-index: 0;
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.bg-overlay:after {
  position: relative;
}

.bg-overlay:after {
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 0;
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.content-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px,1px,1px,1px);
  border: 0;
  overflow: hidden
}


/* Dropcap */

.dropcap {
  font-size: 48px;
  line-height: 60px;
  padding: 0 7px;
  display: inline-block;
  font-weight: 700;
  margin: 5px 15px 5px 0;
  position: relative;
  text-transform: uppercase;
}

.dropcap.primary {
  background: #ff6500;
  color: #fff;
}

.dropcap.secondary {
  background: #3a5371;
  color: #fff;
}


/* Title */

.title {
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 20px;
}


/* Title Border */

/* Section title */
.section-title{
  font-size: 18px;
  line-height: 28px;
  margin:0;
  text-transform: uppercase;
  font-weight: 300;
}

.section-sub-title{
  font-weight: 900;
  font-size: 36px;
  line-height: 46px;
  margin: 0 0 60px;
  color: #212121;
  @include mobile {
    font-size: 28px;
  }
}

/* Column title */

.column-title{
  margin: 0 0 40px;
}

.column-title-small{
  font-size: 20px;
  margin: 0 0 25px;
}


/* Page content title */

.page-content-title.first {
  margin-top: 0;
}


/* Video responsive */

.embed-responsive {
  padding-bottom: 56.25%;
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
}

.embed-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}


/* Input form */

.form-control {
  box-shadow: none;
  border: 1px solid #dadada;
  padding: 5px 20px;
  height: 44px;
  background: none;
  color: #959595;
  font-size: 14px;
  border-radius: 0;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #ff6500;
}

hr {
  background-color: #e7e7e7;
  border: 0;
  height: 1px;
  margin: 40px 0;
}

.alert hr {
  background: initial;
  margin: initial;
  border-top: 1px solid;
  height: initial;
  margin-bottom: 1rem;
  opacity: .2;
}

blockquote {
  padding: 25px;
  margin: 20px 0;
  background: #f9f9f9;
  position: relative;
  border-left: 3px solid $color-primary;
  padding-left: 40px;
  &.text-center {
    border-top: 3px solid $color-primary;
    border-left: 0;
    padding-left: 25px;
    padding-top: 50px;
    &:before {
      left: 50%;
      top: 10px;
      transform: translateX(-50%);
    }
  }
  &.text-right {
    border-right: 3px solid $color-primary;
    padding-right: 40px;
    border-left: 0;
    padding-left: 25px;
    &:before {
      left: auto;
      right: 5px;
    }
  }

  &:before {
    content: "\f10d";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    font-size: 28px;
    color: #E5E5E5;
    top: 5px;
    left: 5px;
    font-weight: 700;
    z-index: 0;
  }
}

blockquote p {
  font-size: 14px;
  line-height: 22px;
  position: relative;
  z-index: 1;
  font-style: italic;
  margin-bottom: 0;
}

cite {
  display: block;
  font-size: 14px;
  margin-top: 10px;
}

/* Ul, Ol */

.list-round,
.list-arrow,
.list-check {
  list-style: none;
  margin: 0;
  padding: 0 0 0 15px;
}

.list-round li {
  line-height: 28px;
}

.list-round li:before {
  font-family: "Font Awesome 5 Free";
  content: "\f138";
  margin-right: 10px;
  color: #ff6500;
  font-size: 12px;
}

.list-arrow {
  padding: 0;
}

ul.list-arrow li:before {
  font-family: "Font Awesome 5 Free";
  content: "\f105";
  margin-right: 10px;
  color: #ff6500;
  font-size: 16px;
  font-weight: bold;
}

ul.list-check li:before {
  font-family: "Font Awesome 5 Free";
  content: "\f00c";
  margin-right: 10px;
  color: #ff6500;
  font-size: 14px;
}

/* Bootstrap */

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover,
.nav-tabs>li>a:hover,
.nav-tabs>li>a {
  border: 0;
}

.nav>li>a:focus,
.nav>li>a:hover {
  background: none;
}

.pattern-bg {
  background: #f5f5f5 url(../images/crossword.png);
}

a[href^=tel] {
  color: inherit;
  text-decoration: none;
}